<div class="login-wrapper">
  <span class="logo">
    <img [src]="environment.logoImage" alt=""/>
  </span>
  <form *ngIf="formState$ | async as formState" [formGroup]="loginForm" class="login-form">
    <div class="login-form__title">Login</div>
    <label>
      <span>ID or Email</span>
      <input type="text" formControlName="email" data-e2e="login-email"/>
    </label>
    <label>
      <span>Password</span>
      <input type="password" formControlName="password" data-e2e="login-password"/>
    </label>
    <span *ngIf="formState.errors" class="login-form__error">Wrong email or password</span>
    <div class="login-form__buttons">
      <button (click)="signIn()" [disabled]="formState.errors" class="login-btn" data-e2e="login-submit">Log In</button>
      <button
        *ngIf="isLoginWithMicrosoft"
        (click)="getLoginUseMicrosoft()"
        class="sign-with-microsoft">
        <img src="assets/img/icons/icons8-microsoft.svg" alt="microsoft"/>
        Sign in
      </button>
    </div>
  </form>
</div>
