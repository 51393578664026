import { Injectable } from '@angular/core';
import { merge, Subject, switchMap, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PopupFactoryService } from '../popups/popup-factory.service';
import { MessagePopupComponent } from '../popups/message-popup/message-popup.component';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { Store } from '@ngxs/store';
import { Logout } from '../store/app.actions';

const POPUP_TIMEOUT = environment.sessionPopupTimeout || 1620000; // 27 minutes
const LOGOUT_TIMEOUT = environment.sessionTimeout || 1800000; // 30 minutes

@Injectable({
  providedIn: 'root',
})
export class SessionCheckerService {
  private requests$ = new Subject<void>();
  private startPopupCountdown$ = new Subject<void>();
  private startLogoutCountdown$ = new Subject<void>();

  private popupCountdown = timer(POPUP_TIMEOUT);
  private logoutCountdown = timer(LOGOUT_TIMEOUT);

  constructor(
    private popup: PopupFactoryService,
    private auth: AuthService,
    private store: Store,
  ) {
    const requests = this.requests$.pipe(debounceTime(500));
    merge(this.startPopupCountdown$, requests)
      .pipe(switchMap(() => this.popupCountdown))
      .subscribe(() => this.openPopup());
    merge(this.startLogoutCountdown$, requests)
      .pipe(switchMap(() => this.logoutCountdown))
      .subscribe(() => this.store.dispatch(new Logout()));
  }

  openPopup() {
    this.popup
      .createPopup({
        popupComponent: MessagePopupComponent,
        popupData: {
          yellowHeader: true,
          message: 'The session is about to expire soon',
          closeBtnText: 'Extend Session',
          closeWithoutRedirect: true,
          closeBtnClass: 'violet',
          closeBtnPayload: true,
          hideCloseBtn: true,
        },
        preventBgClick: true,
      })
      .subscribe(({ data }) => {
        if (data) {
          this.extendSession();
        }
      });
  }

  pushRequest() {
    this.requests$.next();
  }

  extendSession() {
    this.auth.extendSession();
    this.startPopupCountdown$.next();
    this.startLogoutCountdown$.next();
  }
}
