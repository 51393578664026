import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { catchError } from 'rxjs/operators';
import { OpenMessagePopup } from '../../dashboard/store/dashboard.actions';

@Injectable()
export class ErrorMessageInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(event => {
        if ((event.error || {}).notifyUser) {
          const closeWithoutRedirect: boolean = request.url.includes('notify');
          this.store.dispatch(new OpenMessagePopup(event.error.message, event.error, closeWithoutRedirect));
        }
        return throwError(() => event);
      }),
    );
  }
}
