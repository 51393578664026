import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayJs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayJs.extend(relativeTime);
dayJs.extend(durationPlugin);
dayJs.extend(utc);
dayJs.extend(customParseFormat);

Sentry.init({
  dsn: environment.sentryDns,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [environment.apiUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: environment.tracesSampleRate,
});

if (environment.production) {
  enableProdMode();
}

// eslint-disable-next-line no-console
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
