import { Injectable } from '@angular/core';
import { PopupFactoryService } from './popups/popup-factory.service';
import { interval, Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MessagePopupComponent } from './popups/message-popup/message-popup.component';
import { environment } from '../environments/environment';
import { SwUpdate } from '@angular/service-worker';

const VERSION_CHECK_INTERVAL: number = 90000; // 1.5 min;

@Injectable({
  providedIn: 'root',
})
export class VersionCheckerService {
  private version: number = environment.version;

  constructor(
    private popupService: PopupFactoryService,
    private http: HttpClient,
    private swUpdate: SwUpdate,
  ) {}

  triggerFirstCheck(): void {
    this.checkVersion()
      .pipe(
        tap((res: { version: number }) => {
          if (res?.version && this.version !== res?.version) {
            this.version = res.version;
            this.clearCache();
            this.showPopup();
          }
        }),
        take(1),
      )
      .subscribe();
  }

  public startChecking(): void {
    this.triggerFirstCheck();
    interval(VERSION_CHECK_INTERVAL)
      .pipe(
        switchMap(() => this.checkVersion()),
        tap((res: { version: number }) => {
          if (res?.version && this.version !== res?.version) {
            this.version = res.version;
            this.clearCache();
            sessionStorage.clear();
            this.showPopup();
          }
        }),
      )
      .subscribe();
  }

  public showPopup(): void {
    this.popupService
      .createPopup({
        popupComponent: MessagePopupComponent,
        popupData: {
          message: 'You are using an outdated version of the application - reload the page to avoid operational problems',
          closeBtnText: 'Reload',
          yellowHeader: true,
          closeWithoutRedirect: true,
        },
        preventBgClick: true,
        hideCloseBtn: true,
      })
      .pipe(take(1))
      .subscribe(() => {
        window.location.href = location.href;
        window.location.reload();
      });
  }

  private checkVersion(): Observable<any> {
    return this.http.get('/version/');
  }

  private clearCache(): void {
    this.swUpdate.checkForUpdate().then(() => {
      if ('caches' in window) {
        return caches.keys().then((cacheNames: string[]) => {
          cacheNames.forEach((cacheName: string) => {
            caches.delete(cacheName);
          });
        });
      }
    });
  }
}
