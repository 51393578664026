import { CloseConfirmPopup } from '../../dashboard/store/educations/educations.actions';
import { EducationState } from '../../dashboard/store/educations/education.state';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { NgxsModule, Select, Store } from '@ngxs/store';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RoleEnum } from '../../shared/models';
import { NgScrollbarModule, provideScrollbarOptions } from 'ngx-scrollbar';

const defaultHandler: string[] = ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'];

@Component({
  selector: 'app-popup-base',
  templateUrl: './popup-base.component.html',
  styleUrls: ['./popup-base.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, NgxsModule, NgScrollbarModule],
  providers: [
    provideScrollbarOptions({
      orientation: 'vertical',
      // visibility: 'hover',
    }),
  ],
})
export class PopupBaseComponent implements OnInit, OnDestroy {
  @Input() popupComponent: ComponentRef<any>;
  @Input() popupData: any;
  @Input() title: string;
  @Input() subscribers: string[];
  @Input() popupBodyClass: string;
  @Input() isHeightFull: boolean;
  @Input() hideIcon: boolean;
  @Input() hideCloseBtn: boolean;
  @Input() preventBgClick: boolean;
  @Input() isReadLatterConfirm;
  @Input() popupIsImage: boolean;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild('popupContainer', { read: ViewContainerRef, static: true }) popupContainer: ViewContainerRef;

  @Select(EducationState.isCloseDoc) isCloseDoc$: Observable<boolean>;
  @Select(EducationState.isEducationDataLoaded) isEducationDataLoaded$: Observable<boolean>;
  private destroy$: ReplaySubject<void> = new ReplaySubject<void>(1);
  popupSaveState: any;
  config = {
    handlers: defaultHandler,
    wheelPropagation: true,
  };
  instance: any = {};
  currentRole: RoleEnum = this.store.selectSnapshot(({ app }) => app.currentUser?.roles[0]?.title);

  @HostListener('click', ['$event'])
  onOuterClick(event): void {
    if (event.target.tagName.toLowerCase() === 'app-popup-base' && !this.preventBgClick) {
      this.onClose();
    }
  }

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private store: Store,
  ) {
    this.clickPopState();
  }

  ngOnInit(): void {
    if (this.popupContainer) {
      this.popupContainer.clear();
    }
    this.instance = this.popupContainer.createComponent(this.popupComponent as any).instance;
    if (this.popupData) {
      Object.keys(this.popupData || {}).forEach(key => {
        this.instance[key] = this.popupData[key];
      });
    }
    if (this.subscribers) {
      this.popupSaveState = {};
      this.subscribers.forEach(subscriber => {
        this.instance[subscriber].pipe(takeUntil(this.destroy$)).subscribe(data => (this.popupSaveState[subscriber] = data));
      });
    }
    if (this.instance.saveState) {
      this.instance.saveState.subscribe(data => {
        this.popupSaveState = data;
      });
    }
    if (this.instance.closed) {
      this.instance.closed.subscribe(data => {
        this.onClose(data);
      });
    }
    this.subscribeCloseDoc();

    this.popupData?.scrollUpdate$?.pipe(takeUntil(this.destroy$)).subscribe((flag: boolean) => {
      this.config = {
        ...this.config,
        handlers: flag ? [] : defaultHandler,
      };
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.popupContainer.clear();
  }

  onClose(data?): void {
    this.destroy$.next();
    if (this.isReadLatterConfirm) {
      if (this.popupData) {
        if (this.popupData.video.isSeen) {
          this.close.emit({ data, saveState: this.popupSaveState });
        } else {
          this.isEducationDataLoaded$.pipe(take(1)).subscribe((value: boolean) => {
            if (value && this.currentRole === RoleEnum.Employee) {
              this.instance['isConfirm'] = true;
            } else {
              this.close.emit({ data, saveState: this.popupSaveState });
            }
          });
        }
      }
    } else {
      this.instance['isConfirm'] = false;
      this.close.emit({ data, saveState: this.popupSaveState });
    }
  }

  private subscribeCloseDoc(): void {
    this.isCloseDoc$
      .pipe(
        take(2),
        filter((data: any) => data),
      )
      .subscribe(() => {
        if (!this.cd['destroyed']) {
          this.cd.detectChanges();
        }
        this.store.dispatch(new CloseConfirmPopup(false));
        this.close.emit({ saveState: this.popupSaveState });
      });
  }

  private clickPopState(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        takeUntil(this.destroy$),
      )
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.onClose();
        }
      });
  }
}
