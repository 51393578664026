<div class="main-wrapper" cdkScrollable>
  <div *ngIf="showLoader" id="myLoader" class="overlay">
    <img src="/assets/img/datatable/loading.gif" alt="loading"/>
  </div>
  <ngx-loading-bar [color]="'#6469b7'" [includeSpinner]="false"></ngx-loading-bar>
  <router-outlet></router-outlet>
  <ng-template #popupPortal></ng-template>

  <app-connection *ngIf="!(networkStatus$ | async)"></app-connection>
</div>
