import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { LoginByMicrosoftRedirectUrlComponent } from './auth/login-by-microsoft-redirect-url/login-by-microsoft-redirect-url.component';
import { AdmissionDocumentModule } from './dashboard/admission-document/admission-document.module';
import { EducationModule } from './dashboard/education/education.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DocumentModule } from './dashboard/document/document.module';
import { LoginByCliahealthComponent } from './auth/login-by-cliahealth/login-by-cliahealth.component';

const routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/sso',
    component: LoginByMicrosoftRedirectUrlComponent,
  },
  {
    path: 'dashboard',
    loadChildren: (): Promise<typeof DashboardModule> => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'employee',
    loadChildren: (): Promise<typeof DocumentModule> => import('./dashboard/document/document.module').then(m => m.DocumentModule),
  },
  {
    path: 'training',
    loadChildren: (): Promise<typeof EducationModule> => import('./dashboard/education/education.module').then(m => m.EducationModule),
  },
  {
    path: 'admissions',
    loadChildren: (): Promise<typeof AdmissionDocumentModule> =>
      import('./dashboard/admission-document/admission-document.module').then(m => m.AdmissionDocumentModule),
  },
  {
    path: 'cliahealth',
    component: LoginByCliahealthComponent,
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  declarations: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
